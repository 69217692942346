import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { getCacheId } from '../helpers/graphql';
import { apiDomain } from '../consts/env.consts';
import { createUploadLink } from 'apollo-upload-client';

const cable = createConsumer();
const uploadLink = createUploadLink({ uri: `${apiDomain}/graphql` });

const hasSubscriptionOperation = ({ query: { definitions } }) => {
    return definitions.some(({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription');
};

const link = ApolloLink.split(hasSubscriptionOperation, new ActionCableLink({ cable }), uploadLink);

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache({ dataIdFromObject: getCacheId }),
    link,
});
