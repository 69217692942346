/* eslint-disable no-undef */
import { gql } from '@apollo/client';
import { COOKIE_PARAM_NAME } from '../consts/cookies.consts';
import { SET_LOCATION_MUTATION, UPDATE_USER_MUTATION, USER_INVITE_DATE_MUTATION } from '../graphql/user-mutations';
import {
    QUERY_USER_CREDITS,
    QUERY_USER_DETAILS,
    QUERY_USER_DETAILS_SHORT_BY_ID,
    QUERY_USER_DETAILS_SHORT_HOST_MSG_BY_ID,
} from '../graphql/user-queries';
import { locationFromLatLng } from '../helpers/address';
import CookieService from '../services/cookie.service';
import { SPOT_GRAPHQL_ERROR } from './spot.error';
import { getFavoriteSpots } from './favoriteSpots.actions';

export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SET_ALERT_DATA = 'SET_ALERT_DATA';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';

export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';

export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_ERROR = 'UPDATE_USER_DETAILS_ERROR';

export const getUserInfo = () => {
    return (dispatch, _getState, { apolloClient }) => {
        dispatch({ type: GET_USER_INFO });

        return apolloClient
            .query({
                query: gql`
                    {
                        me {
                            id
                            avatarAws
                            hostMode
                        }
                    }
                `,
            })
            .then((resp) => {
                const needShowSubscribe = CookieService.get(COOKIE_PARAM_NAME.SUBSCRIBE) !== 'yes';
                if (resp.data.me !== null && needShowSubscribe) {
                    CookieService.set(COOKIE_PARAM_NAME.SUBSCRIBE, 'yes');
                }
                dispatch({
                    type: GET_USER_INFO_SUCCESS,
                    value: resp.data.me,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_USER_INFO_ERROR,
                    value: err,
                });
            });
    };
};

export const getUserInfoById = (id) => {
    return (dispatch, _getState, { apolloClient }) => {
        return apolloClient
            .query({
                query: QUERY_USER_DETAILS_SHORT_BY_ID,
                variables: { id },
            })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                console.warn(err);
            });
    };
};

export const getUserInfoHostMsgById = (id) => {
    return (dispatch, _getState, { apolloClient }) => {
        return apolloClient
            .query({
                query: QUERY_USER_DETAILS_SHORT_HOST_MSG_BY_ID,
                variables: { id },
            })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                console.warn(err);
            });
    };
};

export const getUserDetails = (force = false) => {
    return (dispatch, _getState, { apolloClient }) => {
        dispatch({ type: GET_USER_INFO });
        dispatch({ type: GET_USER_DETAILS });
        dispatch(getFavoriteSpots());

        return apolloClient
            .query({ query: QUERY_USER_DETAILS, ...(force ? { fetchPolicy: 'network-only' } : {}) })
            .then((resp) => {
                const userShortInfo = {
                    id: resp.data.me.id,
                    avatarAws: resp.data.me.avatarAws,
                    hostMode: resp.data.me.hostMode,
                };
                dispatch(setUserDetails(resp.data.me));
                dispatch({ type: GET_USER_DETAILS_SUCCESS });
                dispatch({ type: GET_USER_INFO_SUCCESS, value: userShortInfo });
            })
            .catch((err) => {
                dispatch({ type: GET_USER_DETAILS_ERROR, value: err });
                dispatch({ type: GET_USER_INFO_ERROR, value: err });
            });
    };
};

const updateUserDetailsFactory =
    (mutation) =>
    (data) =>
    (dispatch, getState, { apolloClient }) => {
        dispatch({ type: UPDATE_USER_DETAILS });

        const {
            userDetails: { id },
        } = getState();

        return apolloClient
            .mutate({ variables: { id, ...data }, mutation })
            .then((resp) => {
                dispatch({ type: SPOT_GRAPHQL_ERROR, error: [] });
                dispatch({ type: UPDATE_USER_DETAILS_SUCCESS });
                dispatch(setUserDetails(resp.data.updateUser));
            })
            .catch((err) => {
                console.warn('error', err);
                dispatch({ type: SPOT_GRAPHQL_ERROR, error: err.graphQLErrors });
                dispatch({ type: UPDATE_USER_DETAILS_ERROR });
            });
    };

export const updateUserDetails =
    (user) =>
    (dispatch, _getState, { apolloClient }) => {
        dispatch({ type: UPDATE_USER_DETAILS });
        const newUser = structuredClone(user);
        newUser.avatarAws = undefined;

        return apolloClient
            .mutate({
                variables: newUser,
                mutation: UPDATE_USER_MUTATION,
            })
            .then((resp) => {
                dispatch({ type: SPOT_GRAPHQL_ERROR, error: [] });
                dispatch({ type: UPDATE_USER_DETAILS_SUCCESS });
                dispatch(setUserDetails(resp.data.updateUser));
                return { errors: resp.errors };
            })
            .catch((err) => {
                console.warn('error', err);
                dispatch({ type: SPOT_GRAPHQL_ERROR, error: err.graphQLErrors });
                dispatch({ type: UPDATE_USER_DETAILS_ERROR });
                return { errors: err.graphQLErrors };
            });
    };

export const updateUserInviteDate = updateUserDetailsFactory(USER_INVITE_DATE_MUTATION);

export const updateUserLocation =
    (latitude, longitude, state, city) =>
    (_dispatch, getState, { apolloClient }) => {
        const {
            userDetails: { id },
        } = getState();

        if (id) {
            return apolloClient
                .mutate({ variables: { id, latitude, longitude, state, city }, mutation: SET_LOCATION_MUTATION })
                .catch((e) => console.warn(e));
        }

        return Promise.resolve();
    };

export const setUserDetails = (data) => (dispatch) => {
    dispatch({ type: SET_USER_DETAILS, data });
};

export const setSpotAlert = (payload) => async (dispatch) => {
    const location = await locationFromLatLng(payload.latitude, payload.longitude);
    dispatch({ type: SET_ALERT_DATA, payload: { ...payload, address: location ? location.address : '' } });
};

export const setAlertFromFilters = (latitude, longitude) => (dispatch, getState) => {
    const {
        filters: {
            filters: { dogsPresent, size, enclosureType },
        },
    } = getState();

    const payload = {
        latitude,
        longitude,
        ...(size && { minimumSize: size.min }),
        dogsAllowed: dogsPresent,
        enclosureType: (enclosureType && String(enclosureType).toLowerCase()) || null,
    };
    dispatch(setSpotAlert(payload));
};

export const getUserCredits =
    () =>
    async (dispatch, _getState, { apolloClient }) => {
        try {
            const {
                data: { me },
            } = await apolloClient.query({ query: QUERY_USER_CREDITS, fetchPolicy: 'no-cache' });
            dispatch(setUserDetails(me));
        } catch (e) {
            console.warn(e);
        }
    };

export const setUserLocationThunk = (value) => (dispatch) => {
    dispatch({ type: SET_USER_LOCATION, value: value });
};
